import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import initializeGTM from './utils/GoogleTagManager'
import HubSpotScript from './utils/HubSpotScript'


if (!process.env.REACT_APP_ENVIRONMENT.includes("development")) {
  Sentry.init({
    dsn: 'https://cd7bb079a80e553bd38417808e9e2ffc@o4505763877224448.ingest.sentry.io/4505820986802176',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/spokbee\.com\/api/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
  LogRocket.init('t5imbg/spokbee');

}
// initializeGTM('GTM-W6657C63');
// HubSpotScript()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
